import getFieldError from 'common/helpers/get-field-error';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import MainFilePicker from './main-file-picker';
import PreviewPicker from './preview-picker';
import { MainPreviewContainer, PickerContainer } from './styles';
import { InputProps } from '../field-type';
import { FormContext } from '../form';

export interface SingleFilePickerProps extends InputProps {
  type: 'file';
  label?: string;
  required?: boolean;
  previewName: string;
  disabled?: boolean;
  maxWidth?: string;
  resizeImage?: boolean;
  resizeWidth?: number;
  resizeHeight?: number;
}

export default function SingleFilePicker(props: SingleFilePickerProps) {
  const {
    maxWidth = '300px',
    resizeHeight,
    resizeWidth,
    resizeImage,
    // required,
  } = props;
  const context = React.useContext(FormContext);
  const disabled = props.disabled || !context.editable;
  const { control } = useFormContext<any>();

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field, fieldState, formState }) => {
        return (
          <Controller
            name={props.previewName}
            control={control}
            render={({
              field: previewField,
              fieldState: previewFieldState,
              formState: previewFormState,
            }) => {
              const _onPicked = (file: string, fileUrl: string) => {
                field.onChange(file);
                previewField.onChange(fileUrl);
              };
              const _onRemove = () => {
                field.onChange(undefined);
                previewField.onChange(undefined);
              };
              const hasValue = !!field.value && !!previewField.value;
              const error = getFieldError(
                props.name,
                formState.errors,
              )?.message;
              return (
                <>
                  <PickerContainer css={{ maxWidth }}>
                    {hasValue && (
                      <MainPreviewContainer>
                        <PreviewPicker
                          disabled={disabled}
                          onDelete={_onRemove}
                          preview={previewField.value}
                          onPicked={_onPicked}
                          resizeHeight={resizeHeight}
                          resizeImage={resizeImage}
                          resizeWidth={resizeWidth}
                        />
                      </MainPreviewContainer>
                    )}
                    {!hasValue && (
                      <MainFilePicker
                        isError={!disabled && !!error}
                        disabled={disabled}
                        onPicked={_onPicked}
                        resizeHeight={resizeHeight}
                        resizeImage={resizeImage}
                        resizeWidth={resizeWidth}
                      />
                    )}
                  </PickerContainer>
                </>
              );
            }}
          />
        );
      }}
    />
  );
}
